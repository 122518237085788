@import url(https://fonts.googleapis.com/earlyaccess/amiri.css);
@import url(https://fonts.googleapis.com/earlyaccess/droidarabickufi.css);
@import url(https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css);
@import url(https://fonts.googleapis.com/earlyaccess/lateef.css);
@import url(https://fonts.googleapis.com/earlyaccess/scheherazade.css);
@import url(https://fonts.googleapis.com/earlyaccess/thabit.css);
@import url(https://fonts.cdnfonts.com/css/madani);

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.App {
  height: auto;
  position: relative;
}

#root {
  height: fit-content;
}

body {
  font-family: 'Baloo Bhaijaan 2';
}

* {
  font-family: 'Baloo Bhaijaan 2';
}

div {
  font-family: 'Baloo Bhaijaan 2';
}

.landing-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-family: 'Baloo Bhaijaan 2';
  position: relative;
}

.landing-page a:link {
  text-decoration: none;
  color: #004e8e;
}

.landing-page a:visited {
  text-decoration: none;
  color: #004e8e;
}

.landing-page a:hover {
  text-decoration: none;
}

.landing-page a:active {
  text-decoration: none;
}

.landing-page .imgsldcon {
  position: relative;
  top: 1000px;
}

.landing-page .simg {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page .newTit {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #00000045;
  color: #d9d9d9;
  font-size: large;
  top: -110px;
}

.landing-page .newTit p {
  height: 100px !important;
}

.landing-page .framCont {
  height: 100%;
  position: relative;
}

.landing-page .framCont div {
  height: 100%;
}

.landing-page .div-2 {
  background-color: #ffffff;
  /* height: 4800px; */
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.landing-page .overlap-2 {
  height: 746px;
  left: 0;
  position: static;
  margin-top: 200px;
  top: 260px;
  width: 100%;
}

.landing-page .relativecont {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.landing-page .relativecontEn {
  position: relative;
  flex-flow: row;
  width: 100%;
  height: 100%;

}

.landing-page .staticEn {
  position: relative;
  display: flex;
  flex-flow: row;
  height: 80%;
  overflow: hidden;
  width: 80%;
  margin: 20px;
  overflow-x: scroll;
}

/* width */
.landing-page .staticEn::-webkit-scrollbar,
.rmCenter .frame-2::-webkit-scrollbar {
  width: 10px;
}

.landing-page .frame-2::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  border: 4px solid #004e8e;
  background-color: #e5f3f5;
  width: 10%;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #004e8e;
  border-radius: 999px;
  width: 10%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #92dbeb;
  border: 3px solid #004e8e;
}

.landing-page .overlap-3 {
  height: 100%;
  margin-bottom: -10px;
  position: absolute;
  direction: rtl;
  right: 0;
  top: 208px;
  z-index: +1;
  width: 40%;
}

.landing-page .debate {
  top: 1100px;
  left: 50px;
  display: flex;
  align-items: center;
}

.landing-page .ency {
  left: 80px;
}

.landing-page .text-wrapper-2 {
  color: #004e8e;
  direction: rtl;
  font-size: 96px;
  font-weight: 400;
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: left;
  top: 0;
  width: 193px;
}

.landing-page .text-wrapper-3 {
  color: #004e8e;
  direction: rtl;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: left;
  top: 114px;
  width: 169px;
}

.landing-page .overlap-4 {
  left: 0;
  position: static;
  top: 0;
  width: 100%;
}

.landing-page .politicians-talking {
  object-fit: cover;
  position: relative;
  top: -40px;
  width: 100%;
}

.landing-page .qimaHome {
  font-family: "Alexandria", sans-serif;
  top: -410px;
  position: relative;
  direction: rtl;
  margin: 50px;
  font-size: 62pt;
  color: #119ab1;
  text-shadow: 2px 2px #000000;
}

.landing-page .qimaHomeTxt {
  top: -460px;
  position: relative;
  direction: rtl;
  margin: 30px;
  font-family: "Readex Pro", sans-serif;
  font-size: 30pt;
  font-weight: 600;
  color: #119ab1;
}

.landing-page .p {
  color: #004e8e;
  direction: rtl;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  width: 100%;
}

.landing-page .overlap-5 {
  position: relative;
  direction: rtl;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.landing-page .ellipse {
  height: 608px;
  left: 978px;
  position: absolute;
  top: 0;
  width: 421px;
}

.landing-page .ellipse-67 {
  background-color: #FBEBB2;
  border-radius: 247px/237.5px;
  box-shadow: 0px 4px 4px #00000040;
  height: 475px;
  justify-content: center;
  position: relative;
  width: 494px;
  align-items: center;
  margin-left: 60%;
  direction: rtl;
  text-align: center;
  display: flex;
}

.landing-page .rectangle-14 {
  background-color: #92dbeb80;
  border-radius: 0px 100px 0px 100px;
  min-height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -200px;
  width: 80%;
  flex-flow: column;
  padding-bottom: 50px;
}

.landing-page .text-wrapper-4 {
  color: #004e8e;
  direction: rtl;
  font-family: "Changa", sans-serif;
  font-size: 48px;
  font-weight: 600;
  left: 80%;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  top: 261px;
  width: 134px;
}

.landing-page .frame {
  left: 49px;
  overflow: hidden;
  position: static;
  top: 308px;
  width: 80%;
  height: 100%;
  margin-top: 50px;
}

.landing-page .group {
  height: 526px;
  left: 1513px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .overlap-group-2 {
  background-color: #d9d9d9;
  height: 526px;
  position: relative;
  width: 340px;
}

.landing-page .rectangle-15 {
  background-color: #cacaca;
  height: 59px;
  left: 22px;
  position: absolute;
  top: 34px;
  width: 291px;
}

.landing-page .rectangle-16 {
  background-color: #cacaca;
  height: 255px;
  left: 22px;
  position: absolute;
  top: 136px;
  width: 291px;
}

.landing-page .text-wrapper-5 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 16px;
  font-weight: 100;
  height: 25px;
  left: 123px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 465px;
  width: 95px;
}

.landing-page .overlap-wrapper {
  height: 526px;
  left: 791px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .overlap-group-wrapper {
  height: 526px;
  left: 430px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .div-wrapper {
  height: 526px;
  left: 1152px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .group-2 {
  height: 526px;
  left: 1874px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .group-3 {
  height: 526px;
  left: 69px;
  position: absolute;
  top: 73px;
  width: 342px;
}

.landing-page .group-4 {
  height: 34px;
  left: 466px;
  position: absolute;
  top: 976px;
  width: 118px;
}

.landing-page .overlap-6 {
  height: 34px;
  position: relative;
  width: 116px;
}

.landing-page .text-wrapper-6 {
  color: #004e8e;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  margin-bottom: 50px;
  width: fit-content;
  border-bottom: 2px solid #004E8E;
}

.landing-page .line-3 {
  height: 2px;
  left: 9px;
  position: absolute;
  top: 32px;
  width: 99px;
}

.landing-page .polygon {
  height: 52px;
  left: 992px;
  position: absolute;
  top: 648px;
  width: 31px;
}

.landing-page .overlap-7 {
  min-height: 1100px;
  position: relative;
  width: 100%;
}

.landing-page .rectangle-17 {
  background-color: #92dbeb;
  border-radius: 0px 100px 0px 100px;
  box-shadow: 0px 4px 4px #00000040;
  height: 300px;
  left: 5%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 65px; */
  width: 30%;
  margin-left: 70px;
  left: 0;

}

.landing-page .ellipse-2 {
  background-color: #fbebb280;
  border-radius: 100%;
  height: 1200px;
  top: 0;
  position: relative;
  padding: 0;
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  width: 1200px;
  margin-top: 100px;
}

.landing-page .ellipse-2 .staticcont {
  position: static;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page .polygon-2 {
  height: 31px;
  left: 899px;
  position: absolute;
  top: 981px;
  width: 52px;
}

.landing-page .text-wrapper-7 {
  color: #004e8e;
  direction: rtl;
  font-family: "Changa", sans-serif;
  font-size: 46px;
  font-weight: 600;
  left: 10%;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 201px;
  width: 256px;
}

.landing-page .group-5 {
  height: 39px;
  left: 818px;
  position: absolute;
  top: 1111px;
  width: 200px;
}

.landing-page .overlap-group-3 {
  height: 39px;
  position: relative;
  width: 198px;
}

.landing-page .line-4 {
  height: 2px;
  left: 29px;
  position: absolute;
  top: 32px;
  width: 140px;
}

.landing-page .text-wrapper-8 {
  color: #004e8e;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 198px;
}

.landing-page .frame-2 {
  height: 700px;
  width: 60%;
  margin-top: 50px;
  overflow: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  flex-flow: column;
  display: flex;
  position: relative;
}

.landing-page .group-6 {
  height: 100%;
  left: 49px;
  margin: 20px;
  position: static;
  top: 45px;
  display: contents;
  width: 100%;
}

.landing-page .group-6enc {
  height: 100%;
  left: 49px;
  margin: 20px;
  position: static;
  top: 45px;
}

.landing-page .overlap-group-4 {
  background-color: #e3e3e37a;
  height: max-content;
  position: relative;
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.landing-page .overlap-group-4enc {
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  direction: rtl;
  width: 800px;
  z-index: 1;
}

.landing-page .rectangle-18 {
  background-color: #fbebb24f;
  min-height: 40px;
  direction: rtl;
  position: static;
  color: #004e8e;
  font-size: large;
  top: 23px;
  align-items: center;
  display: flex;
  margin-bottom: 70%;
  margin: 10px;
  padding: 5px;
  width: 80%;
}

.landing-page .rectangle-18 div {
  position: static;
}


.landing-page .rectangle-18enc {
  background-color: #fbebb24f;
  min-height: 40px;
  direction: rtl;
  color: #004e8e;
  font-size: large;
  position: static;
  top: 23px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 70%;
  margin: 10px;
  padding: 5px;
  width: 80%;
}

.landing-page .staticde {
  position: relative;
}

.landing-page .div {
  position: relative;
}

.landing-page .rectangle-19 {
  background-color: #cacaca;
  left: 38px;
  position: static;
  top: 93px;
  width: 100%;
}

.landing-page .text-wrapper-9 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 18px;
  font-weight: 100;
  height: 17px;
  left: 232px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 317px;
  white-space: nowrap;
  width: 100%;
}

.landing-page .group-7 {
  height: 359px;
  left: 49px;
  position: absolute;
  top: 437px;
  width: 588px;
}

.landing-page .group-8 {
  height: 359px;
  left: 45px;
  position: absolute;
  top: 829px;
  width: 588px;
}

.landing-page .group-9 {
  height: 359px;
  left: 45px;
  position: absolute;
  top: 1221px;
  width: 588px;
}

.landing-page .overlap-8 {
  left: 38px;
  position: relative;
  width: 100%;
  top: 200px;
  margin-bottom: 300px;
  min-height: 500px;
}

.landing-page .ellipse-3 {
  background-color: #92dbeb;
  border-radius: 247px/237.5px;
  box-shadow: 0px 4px 4px #00000040;
  height: 475px;
  position: absolute;
  left: -50px;
  top: -200px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 494px;
  z-index: 2;
}

.landing-page .rectangle-20 {
  background-color: #fbebb280;
  border-radius: 0px 100px 0px 100px;
  min-height: 500px;
  left: 20%;
  position: static;
  justify-content: center;
  display: flex;
  margin-left: 200px;
  align-items: center;
  flex-flow: column;
  top: 0;
  width: 80%;
}

.landing-page .rectangle-21 {
  background-color: #c4c4c4;
  height: 220px;
  left: 769px;
  position: absolute;
  top: 255px;
  width: 320px;
}

.landing-page .text-wrapper-10 {
  color: #004e8e;
  direction: rtl;
  font-family: "Changa", sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  width: 248px;
}

.landing-page .rectangle-22 {
  background-color: #c4c4c4;
  height: 220px;
  left: 0;
  position: absolute;
  top: 244px;
  width: 320px;
}

.landing-page .rectangle-23 {
  background-color: #e7e7e7;
  height: 425px;
  left: 220px;
  position: absolute;
  top: 125px;
  width: 650px;
}

.landing-page .bar {
  background-color: #004e8e;
  border-radius: 50px;
  height: 10px;
  left: 295px;
  position: absolute;
  top: 629px;
  width: 500px;
}

.landing-page .rectangle-24 {
  background-color: #e5f3f5;
  border-radius: 50px;
  height: 2px;
  left: 5px;
  position: relative;
  top: 4px;
  width: 490px;
}

.landing-page .button {
  background-color: #004e8e;
  border-radius: 25px;
  height: 50px;
  left: 520px;
  position: absolute;
  top: 608px;
  width: 50px;
}

.landing-page .ellipse-4 {
  background-color: #e5f3f5;
  border-radius: 15px;
  height: 30px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 30px;
}

.landing-page .group-10 {
  height: 54px;
  left: 399px;
  position: absolute;
  top: 691px;
  width: 294px;
}

.landing-page .overlap-9 {
  height: 54px;
  position: relative;
  width: 292px;
}

.landing-page .text-wrapper-11 {
  color: #004e8e;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 292px;
}

.landing-page .line-5 {
  height: 2px;
  left: 62px;
  position: absolute;
  top: 33px;
  width: 168px;
}

.landing-page .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 5531px !important;

}

.landing-page .component-1 {
  left: 107px !important;
  position: absolute !important;
  top: 1090px !important;
}

.landing-page .component-instance {
  left: 2807px !important;
  top: -617px !important;
}

.landing-page .component-1-instance {
  left: 5375px !important;
  top: -617px !important;
}

.landing-page .component-2 {
  left: 4091px !important;
  top: -617px !important;
}

.landing-page .component-3 {
  left: 6659px !important;
  top: -617px !important;
}

.landing-page .component-4 {
  left: 7943px !important;
  top: -617px !important;
}

.landing-page .component-5 {
  left: 1523px !important;
  top: -617px !important;
}

.landing-page .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

/*footer
*/
.footer {
  background-color: #1c3355;
  height: 350px;
  width: 100%;
  position: relative;
  bottom: 0;
  z-index: +2;
}

.footer .dBy {
  top: 140px;
  text-align: center;
  direction: rtl;
  position: relative;
  color: #ffffff;
}

.footer .dBy a {
  color: #c091ce;
}

.footer .voo {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.footer .footercont {
  position: relative;
  height: 100%;
}

.footer .social-icons {
  height: 48px !important;
  left: 966px !important;
  position: absolute !important;
  top: 185px !important;
  width: 48px !important;
}

.footer .platform-youtube-color-negative {
  height: 48px !important;
  left: 1050px !important;
  position: absolute !important;
  top: 185px !important;
  width: 48px !important;
}

.footer .platform-instagram-color-negative {
  height: 48px !important;
  left: 966px !important;
  position: absolute !important;
  top: 113px !important;
  width: 48px !important;
}

.footer .platform-facebook-color-negative {
  height: 48px !important;
  left: 1050px !important;
  position: absolute !important;
  top: 113px !important;
  width: 48px !important;
}

.footer .text-wrapper {
  color: #e5f3f5;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 600;
  right: 175px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  bottom: 50px;
  white-space: nowrap;
  margin-left: 20px;
}

.footer .element {
  height: 265px;
  left: 20%;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 316px;
}

.footer .overlap {
  height: 33px;
  position: static;
  width: 273px;
  margin-top: 20px;

}

.footer .social {
  left: 65%;
  top: 80px;
  position: absolute;
}

.footer .iqons {
  top: 30%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.footer .iqon {
  width: 30pt;
  margin-left: 30px;
  position: static;
}

.footer .iqon div {
  position: static;
}

.footer .iqons .i {
  margin-right: 10px;
}

.footer .iqons .f {
  margin-right: 23px;
}

.footer .iqons .y {
  margin-right: 0;
}

.footer .iqons .x {
  top: 60px;
  right: 18px;
  width: 50pt;
  margin-left: 40px;
}

.footer .line1 {
  border-left: #FFFFFF 1px solid;
  height: 150px;
  left: 50%;
  object-fit: cover;
  position: relative;
  top: 100px;
  width: 1px;
}

.footer .line2 {
  border-bottom: #FFFFFF 1px solid;
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  width: 273px;
  bottom: 40px;
}

.footer .overlap-group {
  height: 30px;
  left: 0;
  position: relative;
  top: 0;
  width: 273px;
}

.footer .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 273px;
}

.footer .div {
  color: #ffffff;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  left: 65px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 198px;
}

.footer .email {
  left: 35px;
  position: relative;
  top: -30px;
  width: 34px;
}

.footer .img {
  height: 150px;
  left: 719px;
  object-fit: cover;
  position: absolute;
  top: 100px;
  width: 1px;
}


/* header
*/
.header {
  background-color: #ffffff;
  border-bottom: 1px solid;
  border-color: #11abc5;
  height: 102px;
  width: 100%;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 999;
}

.headercls {
  display: inline-flex;
  flex-wrap: wrap;
  position: absolute;
  right: 0;
  height: inherit;
  align-items: center;
}

.header a {
  text-decoration: none;
  color: #000000;
}

.header .forSmallScreens {
  display: none;
}

.header .forSmallScreens,
.dropdownHeader li {
  display: none;
}

.header .dropdownHeader .cls {
  display: none;
}

.header .forSmallScreens .a:visited {
  display: none;
}

.header .cls {
  margin-right: 30px;
  font-size: 18px;
}

.header .arrow {
  border: solid #8F8F8F;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  left: -25px;
  position: absolute;
}

.header .arrow:hover {
  color: #11ABC5;
  cursor: pointer;
}

.header .a:link {
  text-decoration: none;
  color: #000000;
}

.header .a:visited {
  text-decoration: none;
  color: #000000;
}

.header .a:hover {
  text-decoration: none;
  color: #11ABC5;
}

.header .a:active {
  text-decoration: none;
  color: #11ABC5;
}

.header .ddA:link {
  text-decoration: none;
  color: #000000;
}

.header .ddA:visited {
  text-decoration: none;
  color: #000000;
}

.header .ddA:hover {
  text-decoration: none;
}

.header .ddA:active {
  text-decoration: none;
}

.header .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10%;
  margin-top: 10px;
}

.header p {
  margin-right: 30px;
  border: 0;
  font-size: 22px;
}

.header p:hover {
  color: #11ABC5;
  cursor: pointer;
}

.header .element {
  height: 100px;
  left: 10%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 70px;
  padding: 0;
}

.header .hover-instance {
  left: 1225px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .design-component-instance-node {
  direction: rtl !important;
}

.header .hover-2 {
  left: 1318px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .hover-3 {
  left: 837px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .hover-4 {
  left: 613px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .hover-5 {
  left: 440px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .hover-6 {
  left: 1052px !important;
  position: absolute !important;
  top: 25px !important;
}

.header .vector {
  height: 12px;
  left: 92px;
  position: absolute;
  top: 63px;
  width: 20px;
}

.header .dropdown1 {
  background-color: transparent;
  width: auto;
  position: absolute;
  left: -50px;
}

.header .dropdown2 {
  background-color: transparent;
  width: auto;
  position: absolute;
  right: 45px;
}

.header .signAdminDD {
  background-color: transparent;
  width: auto;
  position: absolute;
  top: 60px;
  left: 3%;
}

.header .dd ul {
  position: relative;
  min-width: 150px;
  padding: 0;
  box-shadow: 0 3px 3px #00000040;
  background-color: white;
}

.header .dd .dropdown {
  direction: rtl !important;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: #11ABC5 1px solid;
  align-items: center;
  background-color: #EEEEEE;
}

.header .dd .dropdown:hover {
  background-color: #93DCEC;
  color: #FFFFFF;
  cursor: pointer;
}

.userEmail {
  direction: rtl !important;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 11pt;
  align-items: center;
  min-width: 60%;
  color: gray;
  border-bottom: 1px solid #dbdbdb;
}

/*imgsComponent
*/

.component {
  height: 630px;
  overflow: hidden;
  position: relative;
  width: 1226px;
}

.component .rectangle {
  height: 630px;
  object-fit: cover;
  position: absolute;
  top: 1336px;
  width: 1226px;
}

.component .img {
  height: 630px;
  object-fit: cover;
  position: absolute;
  width: 1226px;
}

.component .rectangle-2 {
  height: 630px;
  object-fit: cover;
  position: absolute;
}

.component .rectangle-3 {
  height: 630px;
  object-fit: cover;
  position: absolute;
  width: 1226px;
}

.component .rectangle-4 {
  height: 630px;
  object-fit: cover;
  position: absolute;
}

.component .rectangle-5 {
  height: 630px;
  object-fit: cover;
  position: absolute;
  width: 1226px;
}

.component .rectangle-6 {
  height: 630px;
  object-fit: cover;
  position: absolute;
}

.component.slider-1 {
  background-position: 50% 50%;
}

.component.slider-4 .rectangle {
  left: -7826px;
}

.component.slider-6 .rectangle {
  left: -13270px;
}

.component.slider-1 .rectangle {
  left: 2914px;
}

.component.slider-2 .rectangle {
  left: -2375px;
}

.component.slider-5 .rectangle {
  left: -10542px;
}

.component.slider-3 .rectangle {
  left: -5102px;
}

.component.slider-7 .rectangle {
  left: -15998px;
}

.component.slider-4 .img {
  left: -5258px;
  top: 1336px;
}

.component.slider-6 .img {
  left: -10702px;
  top: 1336px;
}

.component.slider-1 .img {
  left: 5482px;
  top: 1336px;
}

.component.slider-2 .img {
  left: 193px;
  top: 1336px;
}

.component.slider-5 .img {
  left: -7974px;
  top: 1336px;
}

.component.slider-3 .img {
  left: 0;
  top: 0;
}

.component.slider-7 .img {
  left: -13430px;
  top: 1336px;
}

.component.slider-4 .rectangle-2 {
  left: -2690px;
  top: 1336px;
  width: 1226px;
}

.component.slider-6 .rectangle-2 {
  left: -8134px;
  top: 1336px;
  width: 1226px;
}

.component.slider-1 .rectangle-2 {
  left: 4198px;
  top: 1336px;
  width: 1226px;
}

.component.slider-2 .rectangle-2 {
  left: 2761px;
  top: 1336px;
  width: 1226px;
}

.component.slider-5 .rectangle-2 {
  left: 8px;
  top: 0;
  width: 1218px;
}

.component.slider-3 .rectangle-2 {
  left: 34px;
  top: 1336px;
  width: 1226px;
}

.component.slider-7 .rectangle-2 {
  left: -10862px;
  top: 1336px;
  width: 1226px;
}

.component.slider-4 .rectangle-3 {
  left: 0;
  top: 0;
}

.component.slider-6 .rectangle-3 {
  left: -9418px;
  top: 1336px;
}

.component.slider-1 .rectangle-3 {
  left: 6766px;
  top: 1336px;
}

.component.slider-2 .rectangle-3 {
  left: 1477px;
  top: 1336px;
}

.component.slider-5 .rectangle-3 {
  left: -6690px;
  top: 1336px;
}

.component.slider-3 .rectangle-3 {
  left: -1250px;
  top: 1336px;
}

.component.slider-7 .rectangle-3 {
  left: -12146px;
  top: 1336px;
}

.component.slider-4 .rectangle-4 {
  left: -1406px;
  top: 1336px;
  width: 1226px;
}

.component.slider-6 .rectangle-4 {
  left: 4px;
  top: 0;
  width: 1222px;
}

.component.slider-1 .rectangle-4 {
  left: 8050px;
  top: 1336px;
  width: 1226px;
}

.component.slider-2 .rectangle-4 {
  left: 4045px;
  top: 1336px;
  width: 1226px;
}

.component.slider-5 .rectangle-4 {
  left: -4122px;
  top: 1336px;
  width: 1226px;
}

.component.slider-3 .rectangle-4 {
  left: 1318px;
  top: 1336px;
  width: 1226px;
}

.component.slider-7 .rectangle-4 {
  left: -9578px;
  top: 1336px;
  width: 1226px;
}

.component.slider-4 .rectangle-5 {
  left: -122px;
  top: 1336px;
}

.component.slider-6 .rectangle-5 {
  left: -5566px;
  top: 1336px;
}

.component.slider-1 .rectangle-5 {
  left: 1630px;
  top: 1336px;
}

.component.slider-2 .rectangle-5 {
  left: 5329px;
  top: 1336px;
}

.component.slider-5 .rectangle-5 {
  left: -2838px;
  top: 1336px;
}

.component.slider-3 .rectangle-5 {
  left: 2602px;
  top: 1336px;
}

.component.slider-7 .rectangle-5 {
  left: 0;
  top: 0;
}

.component.slider-4 .rectangle-6 {
  left: -6542px;
  top: 1336px;
  width: 1226px;
}

.component.slider-6 .rectangle-6 {
  left: -11986px;
  top: 1336px;
  width: 1226px;
}

.component.slider-1 .rectangle-6 {
  left: -14714px;
  top: 1336px;
  width: 1226px;
}

.component.slider-2 .rectangle-6 {
  left: 3px;
  top: 0;
  width: 1223px;
}

.component.slider-5 .rectangle-6 {
  left: -9258px;
  top: 1336px;
  width: 1226px;
}

.component.slider-3 .rectangle-6 {
  left: -3818px;
  top: 1336px;
  width: 1226px;
}

.component.slider-7 .rectangle-6 {
  left: -14714px;
  top: 1336px;
  width: 1226px;
}

/*news styles
*/

.news {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: static;
}

.news .div-2 {
  background-color: #ffffff;
  height: fit-content;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 1000px;
}

.news .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 3512px !important;
}

.news .groupsContainer {
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  top: 350px;
  width: 90%;
  position: static;
}

.newImg {
  width: 50%;
  margin-bottom: 10px;
  position: static;
}

.news .div-wrapper {
  background-color: #92dbeb;
  border-radius: 30px;
  box-shadow: 0px 4px 4px #00000040;
  height: 102px;
  position: static;
  top: 156px;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  align-items: center;
  width: 60%;
  margin-bottom: 50px;
}

.news .text-wrapper-3,
.encyclopedia .text-wrapper-2 {
  color: #004e8e;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 28px;
  font-weight: 700;
  min-height: 56px;
  left: 112px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 22px;
}

.news .group-1 {
  left: 301px !important;
  position: absolute !important;
  top: 1012px !important;
}

.news .group-instance {
  left: 301px !important;
  position: absolute !important;
  top: 408px !important;
}

.news .group-1-instance {
  left: 301px !important;
  position: absolute !important;
  top: 1616px !important;
}

.news .group-2 {
  left: 301px !important;
  position: absolute !important;
  top: 2220px !important;
}

.news .group-3 {
  left: 301px !important;
  position: absolute !important;
  top: 2824px !important;
}

.news .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.news .group {
  background-color: #ffffff;
  height: auto;
  position: relative;
  display: flex;
  width: 90%;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border: 2px solid #004E8E;
  border-radius: 20px;
}

.news .group .date {
  top: 0;
  right: 50px;
  direction: rtl;
  width: 80%;
  position: static;
  display: flex;
  margin: 10px;
}

.news .group .rectangle {
  background-color: #ededed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  min-height: 62px;
  align-items: center;
  color: #004E8E;
  font-size: larger;
  margin-bottom: 20px;
  position: static;
  top: 30px;
  direction: rtl;
  font-weight: 600;
  padding: 0 40px;
}

.news .group .div,
.encyclopedia .group .div {
  height: auto;
  width: 70%;
  background-color: #ededed;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  color: #004E8E;
  font-size: large;
  position: static;
  padding: 20px;
  direction: rtl;
}

.news .group .text-wrapper,
.encyclopedia .group .text-wrapper {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  left: 331px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  white-space: nowrap;
  width: 50%;
}

.news .group .text-wrapper a,
.encyclopedia .group .text-wrapper a {
  font-size: 12pt;
}

/*RMCenter
*/

.rmCenter {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
}

.videoCon {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.videoCon video {
  height: 400px;
}

.rmCenter .fr {
  width: 1000px;
}

.rmCenter .div-3 {
  background-color: #ffffff;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rmCenter .overlap-2 {
  height: 546px;
  left: 0;
  position: static;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rmCenter .rmCenterText {
  direction: rtl;
  width: 80%;
  color: #004E8E;
}

.rmCenter .element-3 {
  height: 550px;
  right: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 80%;
}

.rmCenter .element-4 {
  height: 496px;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 100%;
}

.rmCenter .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 3146px !important;
}

.rmCenter .overlap-3 {
  left: 80%;
  position: static;
  width: 100%;
  height: 500px;
  margin-right: 150px;
}

.rmCenter .overlap-3rum {
  left: 80%;
  position: static;
  width: 100%;
  margin-right: 150px;
}

.rmCenter .overlap-3rum .cont {
  position: relative;
  height: 300px;
}


.rmCenter .ellipse {
  background-color: #92dbeb;
  border-radius: 150px;
  height: 300px;
  right: 20px;
  position: relative;
  top: 200px;
  width: 300px;
  margin-left: 80%;
}

.rmCenter .ellipse-2 {
  background-color: #92dbeb;
  border-radius: 175px;
  height: 350px;
  mix-blend-mode: multiply;
  position: relative;
  top: -200px;
  right: 0;
  width: 350px;
  margin-left: 80%;
}

.rmCenter .text-wrapper-5 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  top: -350px;
  width: 310px;
  margin-left: 80%;
}

.rmCenter .frame-2 {
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: center;
  overflow-x: scroll;
  position: static;
  width: 80%;
  height: auto;
  scroll-snap-type: x proximity;
}

.rmCenter .frame-2 .alls {
  height: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  scroll-snap-align: start;
}

.rmCenter .frame-2 .txtgroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.rmCenter .group {
  background-color: #ffffff;
  border-color: #c4c4c4;
  box-shadow: 0px 0px 10px 2px #bfb6b6;
  left: 22px;
  position: static;
  margin: 20px;
  border-radius: 20px;
}

.rmCenter .group .ingroup {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 30px;
  width: 700px;
}

.rmCenter .rectangle {
  background-color: #f2f2f2;
  left: 609px;
  position: static;
  top: 184px;
  padding: 20px;
}

.rmCenter .rectangle-2 {
  background-color: #f2f2f2;
  min-height: 62px;
  left: 609px;
  position: static;
  top: 35px;
  min-width: 60%;
  display: flex;
  margin: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.rmCenter .rectangle-3 {
  background-color: #cacaca;
  height: 300px;
  left: 48px;
  position: static;
  top: 40px;
  margin: 10px;
}

.rmCenter .group-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c4c4c4;
  height: fit-content;
  left: 22px;
  position: static;
  top: 0;
  width: 1000px;
}

.rmCenter .group-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c4c4c4;
  height: 550px;
  left: 1056px;
  position: absolute;
  top: 0;
  width: 1000px;
}

.rmCenter .polygon-wrapper {
  background-color: #d9d9d9;
  height: 464px;
  position: absolute;
  top: 2435px;
  width: 755px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
}

.rmCenter .polygon-wrapper .triangle-right {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 50px solid #C4C4C4;
  border-bottom: 25px solid transparent;
}

.rmCenter .polygon {
  height: 87px;
  left: 353px;
  position: absolute;
  top: 189px;
  width: 75px;
}

.rmCenter .year-droplist-instance {
  left: 618px !important;
  position: absolute !important;
  top: 2204px !important;
}

.rmCenter .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.rmCenter .year-drop-list {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  position: static;
  flex-flow: column;
  width: 80%;
  align-items: center;
}

.rmCenter .yearsDL .list {
  justify-content: left;
  display: flex;
  flex-flow: column;
  width: 80%;
}

.rmCenter .yearsDL .cls {
  text-align: center;
  background-color: #11abc5;
  padding: 20px;
  width: 10%;
  margin-right: 70%;
  border-radius: 30px;
  color: white;
  font-size: larger;
}

.rmCenter .yearsDL .years {
  z-index: 999;
  position: absolute;
  justify-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-height: 100px;
  width: 100px;
  margin-top: -15px;
  padding: 10px;
  box-shadow: 0 3px 3px #00000040;
}

.rmCenter .yearsDL .years div {
  color: #11abc5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rmCenter .yearsDL .years div:hover {
  background-color: #11abc5;
  color: #ffffff;
}

.rmCenter .yearsDL {
  position: static;
  width: 100%;
  display: contents;
}

.rmCenter .rumorcont {
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  width: 80%;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  padding: 50px;
  background-color: #ffffffa6;
  margin-top: 100px;
}

.rmCenter .rumorcont .rum {
  display: flex;
  flex-flow: row;
  box-shadow: 0px 4px 5px 0px gainsboro;
  margin: 20px;
  padding: 10px;
  align-items: center;
}

.rmCenter .rumorcont .rum img {
  height: fit-content;
  width: 60%;
}

.rmCenter .videoCon {
  position: static;
}

.rmCenter .follow {
  font-size: larger;
  margin: 20px;
}

.rmCenter .subCon {
  position: relative;
  right: 0;
  margin: 20px;
  direction: rtl;
  width: 100%;
}

.rmCenter .subCon .tit {
  font-weight: 400;
  font-size: larger;
}


.rmCenter .subCon div {
  margin: 10px;
}

/* aboutUs
*/

.aboutUs {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
}

.aboutUs .div-2 {
  background-color: #ffffff;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 500px;
}

.aboutUs .div-2 .qimaVis {
  width: 70%;
  text-align: center;
}

.aboutUs .div-2 .qimaVis h1 {
  color: #004e8e;
  direction: rtl;
}

.aboutUs .div-2 .qimaVis div {
  text-align: right;
  direction: rtl;
}

.aboutUs .goalsCont {
  display: flex;
  flex-flow: column;
  text-align: center;
  width: 70%;
  /* border: 1px solid; */
  background-color: aliceblue;
  border-radius: 50px;
  padding: 30px;
  color: #004e8e;
}

.aboutUs .goalsCont h1 {
  text-decoration: underline;
  text-decoration-color: #004e8e;
}

.aboutUs .goalsCont .goals {
  display: flex;
  flex-flow: row-reverse;
}

.aboutUs .goalsCont .goals .goal {
  display: flex;
  flex-flow: column;
  margin: 10px;
}

.aboutUs .goalsCont .goals .goal .cont {
  direction: rtl;
}

.aboutUs .aboutUsImg {
  width: 400px;
}

.aboutUs .overlap-2,
.founders .overlap-2 {
  height: auto;
  left: 0;
  position: static;
  top: 0;
  width: 100%;
  margin-bottom: 100px;
}

.aboutUs .element-3,
.founders .element-3 {
  height: 550px;
  right: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 80%;
}

.aboutUs .element-4,
.founders .element-4 {
  height: 496px;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 100%;
}

.aboutUs .text-wrapper-2,
.founders .text-wrapper-2,
.rmCenter .text-wrapper-4 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  width: 90%;
  FONT-VARIANT: JIS78;
  direction: rtl;
  position: absolute;
  top: 248px;
  white-space: nowrap;
}

.aboutUs .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1100px !important;
}

.aboutUs .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.aboutUs .sec {
  direction: rtl;
  text-align: center;
  color: #004e8e;
}

.aboutUs .secs {
  width: 70%;
  margin-top: 500px;
}


.aboutdebates .secs {
  margin-top: 150px;
}

.aboutdebates .sec {
  color: #000000;
  text-align: right;
  font-size: large;
}

.aboutdebates h2 {
  color: #004e8e;
}
/* founders
*/

.founders {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
}

.founders .div-2 {
  background-color: #ffffff;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 500px;
}

.founders .foundersCont {
  margin-top: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.founders .founs {
  display: flex;
  flex-flow: column;
  width: 70%;
  color: #004e8e;
}

.founders .foun {
  display: flex;
  flex-flow: row;
  direction: rtl;
  align-items: center;
  margin-bottom: 50px;
}

.founders .founButh {
  display: flex;
  flex-flow: row-reverse;
  direction: rtl;
  margin-bottom: 50px;
  align-items: center;
}

.founders .container {
  display: flex;
  flex-flow: column;
  margin: 20px;
}

.founders .founImg {
  width: 30%;
  height: fit-content;
}

.founders .OCImg {
  margin-top: 500px;
  width: 80%;
}

/* encyclopedia
*/

.encyclopedia {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.encyclopedia .groupscont {
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  top: 350px;
  width: 90%;
  position: static;
}

.encyclopedia .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 3512px !important;
}


.encyclopedia .group {
  background-color: #ffffff;
  height: auto;
  position: relative;
  display: flex;
  width: 90%;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border: 2px solid #004E8E;
  border-radius: 20px;
  padding: 20px 0;
}

.encyclopedia .group .rectangle {
  background-color: #ededed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  min-height: 62px;
  align-items: center;
  color: #004E8E;
  font-size: larger;
  margin-bottom: 20px;
  position: static;
  top: 30px;
  direction: rtl;
  font-weight: 600;
  padding: 0 40px;
}

.encyclopedia .div-2 {
  background-color: #ffffff;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 1000px;
}

.encyclopedia .div-wrapper {
  background-color: #92dbeb;
  border-radius: 30px;
  box-shadow: 0px 4px 4px #00000040;
  min-height: 102px;
  position: static;
  top: 156px;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  align-items: center;
  width: 60%;
  margin-bottom: 50px;
}

.encyclopedia .overlap-2 {
  background-color: #d9d9d9;
  height: 550px;
  position: relative;
  width: 838px;
  margin-bottom: 30px;
}

.encyclopedia .rectangle-2 {
  background-color: #cacaca;
  height: 266px;
  left: 55px;
  position: absolute;
  top: 142px;
  width: 716px;
}

.encyclopedia .text-wrapper-3 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  height: 26px;
  left: 317px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 485px;
  white-space: nowrap;
  width: 192px;
}

.encyclopedia .overlap-wrapper {
  height: 550px;
  left: 301px;
  position: absolute;
  top: 1012px;
  width: 840px;
}

.encyclopedia .text-wrapper-4 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  height: 26px;
  left: 317px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 485px;
  white-space: nowrap;
  width: 191px;
}

.encyclopedia .overlap-group-wrapper {
  height: 550px;
  left: 301px;
  position: absolute;
  top: 2220px;
  width: 840px;
}

.encyclopedia .text-wrapper-5 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  height: 26px;
  left: 322px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 485px;
  white-space: nowrap;
  width: 181px;
}

.encyclopedia .group-2 {
  height: 550px;
  left: 301px;
  position: absolute;
  top: 1616px;
  width: 840px;
}

.encyclopedia .text-wrapper-6 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  height: 26px;
  left: 324px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 485px;
  white-space: nowrap;
  width: 190px;
}

.encyclopedia .group-3 {
  height: 550px;
  left: 301px;
  position: absolute;
  top: 2824px;
  width: 840px;
}

.encyclopedia .text-wrapper-7 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 100;
  height: 26px;
  left: 323px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 485px;
  white-space: nowrap;
  width: 191px;
}

.encyclopedia .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

/* subject
*/
.subcont {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.subcont .subImg {
  width: 50%;
}

.subcont .mainSub {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.subject .p img {
  width: 50%;
}

.subject {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  min-height: 1000px;
}

.subject .dot {
  height: 15px;
  width: 15px;
  left: 100%;
  background-color: #004E8E;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -65px;
}

.subject .div-2 {
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  min-height: 700px;
}

.subject .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 674px !important;
}

.subject .overlap-2 {
  flex-flow: column;
  position: static;
  justify-content: center;
  align-items: center;
  bottom: 50%;
  display: flex;
  width: 80%;
}

.subject .mainSub {
  width: 80%;
  direction: rtl;
}

.subject .overlap-3 {
  min-height: 30px;
  right: 20px;
  position: static;
  top: 200px;
  width: 693px;
  /* margin-left: 60%; */
  margin-top: 150px;
  padding: 0;
  border-bottom: 2px solid #004E8E;
  width: auto;
  margin-bottom: 50px;
}

.subject .fotcont {
  position: static;
  width: 100%;
}

.subject .subImg {
  width: 80%;
}

.subject .text-wrapper-2 {
  color: #004e8e;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  width: auto;
  line-height: normal;
}

.subject .line-3 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 693px;
}

.subject .fiber-manual-record {
  height: 20px;
  left: 676px;
  position: absolute;
  top: 29px;
  width: 20px;
}

.subject .p {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 400;
  left: 20%;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  top: 242px;
}

.subject .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}


/*//////////login*/

.login {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.login .div-2 {
  display: flex;
  background-color: #ffffff;
  min-height: 500px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-flow: column;
}

.login .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.login .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 674px !important;
}

.login .text-wrapper-2 {
  color: #11abc5;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 144px;
  width: 325px;
  margin-top: 120px;
}

.login .overlap-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  min-height: 382px;
  position: static;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  margin-bottom: 50px;
  flex-flow: column;
}

.login .overlap-2 .cont {
  display: flex;
  flex-flow: row;
  direction: rtl;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px;
}


.login .text-wrapper-3 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  margin: 10px;
}

.login .text-wrapper-4 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 16px;
  font-weight: 600;
  /* height: 43px; */
  letter-spacing: 0;
  line-height: normal;
  position: static;
  margin: 10px;
}

.login .text-wrapper55 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 16px;
  font-weight: 600;
  height: 43px;
  left: 434px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 80px;
}

.login .rectangle-2 {
  border: 0;
  background-color: #f2f2f2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #11abc5;
  min-height: 38px;
  direction: rtl;
  /* left: 71px; */
  position: static;
  top: 80px;
  width: 60%;
  margin-bottom: 20px;
}

.login .rectangle {
  border: 0;
  background-color: #f2f2f2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #11abc5;
  min-height: 38px;
  direction: rtl;
  /* left: 71px; */
  position: static;
  top: 160px;
  width: 60%;
}

.login .rectangle-2:focus {
  outline: none;
  background-color: #e2f2f5;
  border: 0;
  border-bottom: 4px solid #11abc5;
}

.login .rectangle:focus {
  outline: none;
  background-color: #e2f2f5;
  border: 0;
  border-bottom: 4px solid #11abc5;

}


.login .div-wrapper {
  background-color: #11abc5;
  border-radius: 20px;
  box-shadow: 0px 4px 4px #00000040;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 278px;
  width: 258px;
}

.login .text-wrapper-5 {
  color: #ffffff;
  border: 0;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  width: 154px;
  margin-top: 30px;
}

.login .text-wrapper-5:hover {
  color: #11abc5;
}

.login .div-wrapper:hover {
  background-color: #ffffff;
  border: 1px solid #11abc5;
  border-radius: 20px;
  box-shadow: 0 0;
  cursor: pointer;
}


/*////////// dashboard*/
.dashboard {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
}

.dashboard video {
  width: 80%;
  max-height: 500px;
}

.dashboard .navAdmin {
  width: 100%;
  text-decoration: none;
}

.dashboard .mainCont {
  height: max-content;
  width: 80%;
  display: flex;
  justify-content: center;
  left: 0;
  position: relative;
  min-height: 1000px;
}

.dashboard .div-2 {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  display: flex;
}

.dashboard .design-component-instance-node-2 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.dashboard .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1357px !important;
}

.dashboard .overlap-4 {
  background-color: #11abc5;
  height: 100%;
  right: 0;
  position: fixed;
  top: 102px;
  z-index: +1;
  width: 20%;
}

.dashboard .overlap-4 .DBTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.dashboard .text-wrapper-3 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 24px;
  font-weight: 600;
  left: 40px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 34px;
  width: 136px;
}

.dashboard .div-wrapper {
  background-color: #A4DCE5;
  border-color: #11ABC5;
  color: #006172;
  font-size: 14pt;
  border-top-style: solid;
  border-top-width: 2px;
  min-height: 51px;
  list-style: none;
  left: 0;
  position: static;
  margin: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px;
  width: 100%;
}

.dashboard .div-wrapper:hover {
  background-color: #11ABC5;
  border-color: #A4DCE5;
  color: #A4DCE5;
  cursor: pointer;
}

.dashboard .dashboardList {
  display: flex;
  top: 100px;
  position: absolute;
  flex-wrap: wrap;
  padding: 0;
  border-bottom: 2px solid #A4DCE5;
}

.dashboard .text-wrapper-4 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 99px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 67px;
}

.dashboard .overlap-5 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 269px;
  width: 270px;
}

.dashboard .text-wrapper-5 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 99px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 67px;
}

.dashboard .overlap-6 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 218px;
  width: 270px;
}

.dashboard .text-wrapper-6 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 83px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 100px;
}

.dashboard .overlap-7 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 167px;
  width: 270px;
}

.dashboard .text-wrapper-7 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 80px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 106px;
}

.dashboard .dashboard-customize {
  height: 48px;
  left: 176px;
  position: static;
  top: 34px;
  width: 49px;
}

.dashboard .overlap-8 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 320px;
  width: 270px;
}

.dashboard .text-wrapper-8 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 184px;
}

.dashboard .edit {
  height: 96px;
  left: 249px;
  position: absolute;
  top: 540px;
  width: 21px;
}

.dashboard .overlap-9 {
  background-color: #a4dce5;
  border-radius: 10px;
  color: #006171;
  font-size: 15pt;
  box-shadow: 0px 4px 4px #00000040;
  height: 86px;
  right: 50px;
  position: absolute;
  top: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 242px;
}

.dashboard .overlap-9:hover {
  background-color: #11abc5;
  color: #ffffff;
  cursor: pointer;
}

.dashboard .text-wrapper-9 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  width: 92px;
}

.dashboard .create {
  position: absolute;
  display: block;
  right: 20px;
  width: 30px;
}

.dashboard .overlap-10 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  position: relative;
  /* top: 329px; */
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  width: 90%;
  padding-bottom: 70px;
}

.dashboard .overlap-11 {
  height: 64px;
  /* left: 182px; */
  position: absolute;
  /* top: 246px; */
  justify-content: center;
  display: flex;
  width: 100%;
  bottom: 0;
}

.dashboard .delete {
  height: 25px;
  left: 362px;
  position: absolute;
  top: 20px;
  width: 25px;
}

.dashboard .overlap-12 {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d9d9d9;
  min-height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 85px; */
  position: static;
  top: 16px;
  width: 70%;
}

.dashboard .text-wrapper-10 {
  color: #000000;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 100;
  min-height: 35px;
  /* left: 234px; */
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  position: static;
  top: 14px;
  width: auto;
}

.dashboard .p {
  color: #adadad;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 94px;
  width: 80%;
  position: static;
}

.dashboard .text-wrapper-11 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 36px;
  /* left: 1056px; */
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 278px;
  right: 0;
  margin-right: 40px;
}

.dashboard .overlap-13 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  height: 311px;
  left: 38px;
  position: absolute;
  top: 668px;
  width: 1082px;
}

.dashboard .component-1 {
  left: 181px !important;
  position: absolute !important;
  top: 245px !important;
}

.dashboard .overlap-14 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  height: 311px;
  left: 38px;
  position: absolute;
  top: 1007px;
  width: 1082px;
}

.dashboard .component {
  background-color: #a4dce5;
  height: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
}

.dashboard .component .line {
  height: 34px;
  left: 201px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 1px;
}

.dashboard .component .img {
  height: 34px;
  left: 479px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 1px;
}

.dashboard .component .overlap {
  height: 100%;
  right: 5px;
  position: absolute;
  /* top: 14px; */
  width: 30%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlaphh1 {
  height: 100%;
  right: 5px;
  position: absolute;
  /* top: 14px; */
  width: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlap1 {
  height: 100%;
  right: 30%;
  position: absolute;
  /* top: 14px; */
  width: 40%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlap2 {
  height: 100%;
  left: 0;
  position: absolute;
  /* top: 14px; */
  width: 30%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.dashboard .component .overlaphh {
  height: 100%;
  left: 0;
  position: absolute;
  width: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.dashboard .component .text-wrapper {
  color: #11abc5;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 100;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 0;
  width: 100%;
  border-left: 1px solid gray;
}

.dashboard .component .text-wrapper1 {
  color: #11abc5;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 100;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 0;
  width: 100%;
}

.dashboard .component .vector {
  right: 20%;
  position: absolute;
  width: 25px;
  top: 20px;
}

.dashboard .component .vector-2 {
  right: 25%;
  position: absolute;
  width: 20px;
  top: 20px;
}

.dashboard .component .div {
  height: 35px;
  left: 61px;
  position: absolute;
  top: 14px;
  width: 89px;
}

.dashboard .component .eye {
  right: 25%;
  position: absolute;
  width: 35px;
}

.dashboard .cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  top: 20%;
  position: static;
  margin-top: 350px;
  align-items: center;
  flex-direction: column-reverse;
}

.updateScreen {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #f8f9fa; */
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateScreen .updateForm {
  width: 60%;
  height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
  position: static;
  border-radius: 10px;
  background-color: #e5f3f5c2;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 20px;
}

.updateScreen .updateForm img {
  width: 70%;
}

.updateScreen .updateForm input {
  border-radius: 0.6rem;
  width: 70%;
  padding: 10px;
}

#title,
#summary {
  margin-bottom: 70px;
}

#link {
  height: 30px !important;
}

.updateForm .imgCont,
.updateForm .imgCont2 {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.updateForm .imgCont {
  justify-content: center;
  margin: 70px 0;
  align-items: center;
}

.updateForm .sec {
  display: flex;
  flex-flow: column;
}

.updateForm .imgsizeL {
  font-size: 10pt !important;
}

.updateScreen .percent {
  width: 100%;
}

.updateScreen .updateForm textarea {
  border-radius: 0.6rem;
  width: 70%;
  min-height: 100px;
  padding: 20px;
}

.updateScreen .updateForm label {
  font-weight: bolder;
  font-size: 15pt;
  width: 70%;
  margin: 10px;
}

.updateScreen .updateForm .sbmtBtn {
  color: #fff;
  background-color: #0f6cbf;
  border-color: #0f6cbf;
  padding: 0.5rem 1rem;
  font-size: 1.171875rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 100px;
  min-height: 60px;
}

.jodit-status-bar-link {
  display: none;
}

.updateScreen .updateForm button:hover {
  background-color: #0b508b;
}

.updateScreen .cont .addImgBtn {
  height: 50px;
  width: 70px;
  margin: 10px;
  border: 0;
  padding: 5px;
  color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #006171;
}

.updateScreen .cont .addImgBtn:hover {
  background-color: #11ABC5;
}

.updateForm .editText {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: baseline;
  margin: 20px;
  border: 1px solid #cdcdcd;
  padding: 10px;
  font-size: 10pt;
  background-color: #c9c9c945;
}

.updateForm .txtView {
  margin: 50px;
  padding: 20px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 4px 7px 15px 2px #c1c1c1ee;
  justify-content: center;
  direction: rtl;
  display: flex;
  flex-flow: column;
  min-width: 60%;
  min-height: 50px;
}

.updateForm .txtView img {
  width: 50%;
}

.updateForm .cont {
  display: flex;
  flex-flow: column;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
}

.updateForm .cont label {
  font-size: 10pt !important;
}

.updateForm .clr {
  width: 40px !important;
  height: 40px;
  padding: 3px !important;
}


.updateForm .closeUpdate {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 30px;
  width: 30px;
}

@media (max-width: 1200px) {

  .landing-page .ellipse-2 {
    height: 1000px;
    width: 1000px;
  }

  .landing-page .qimaHome {
    font-size: 40pt;
    top: -350px;
  }

  .landing-page .qimaHomeTxt {
    font-size: 25pt;
    top: -400px;
  }
}

@media (max-width:1000px) {
  .landing-page .ellipse-67 {
    height: 350px;
    width: 350px;
  }

  .landing-page .politicians-talking {
    top: 102px;
  }

  .landing-page .qimaHome {
    font-size: 38pt;
    top: -200px;
  }

  .landing-page .qimaHomeTxt {
    font-size: 20pt;
    top: -260px;
  }

  .headercls {
    display: none;
  }

  .header .dropdownHeader .cls {
    display: block;
    margin: 0;
    padding: 0 10px;
  }

  .header .forSmallScreens {
    display: block;
    right: 20px;
    position: absolute;
    width: 40px;
    cursor: pointer;
  }

  .header .dropdownHeader ul {
    position: relative;
    top: 120px;
    width: auto;
    flex-flow: column-reverse;
    padding: 0;
    display: flex;
    box-shadow: 0 3px 3px #00000040;
    background-color: white;
  }

  .header .dropdownHeader ul li ul {
    flex-flow: column;
  }

  .header .dropdownHeader .dropdown {
    direction: rtl !important;
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom: #11ABC5 1px solid;
    align-items: center;
    margin: 0;
    background-color: #EEEEEE;
  }

  .header .dropdownHeader {
    right: 0;
    height: 100%;
    position: absolute;
    direction: rtl !important;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header .dropdownHeader li:hover {
    background-color: #93DCEC;
    color: #FFFFFF;
    cursor: pointer;
  }

  .header .a:hover {
    text-decoration: none;
    color: #ffffff;
  }

  .header .cls:hover {
    background-color: #93DCEC;
  }

  .header .dropdown2 {
    position: absolute;
  }

  .header .dropdown2 ul {
    top: 0;
  }

  .header .dropdown1 ul {
    top: 0;
  }

  .landing-page .overlap-2 {
    margin-top: 50px;
  }

  .landing-page .ellipse-2 {
    height: 800px;
    width: 800px;
  }

  .landing-page .frame-2 {
    height: 400px;
  }

  .landing-page .rectangle-17 {
    height: 200px;
  }

  .landing-page .text-wrapper-7 {
    font-size: 40px;
  }

  .landing-page .text-wrapper-10 {
    font-size: 40px;
  }

  .landing-page .text-wrapper-4 {
    font-size: 40px;
  }

  .landing-page .ellipse-3 {
    height: 350px;
    width: 350px;
  }

  .landing-page .overlap-7 {
    margin-top: 0;
  }


  .footer .element {
    right: 60%;
    left: 0;
  }

  .footer .social {
    left: 60%;
    right: 10px;
    top: 80px;
    position: absolute;
  }

  .rmCenter video {
    width: 100%;
  }

  .dashboard .overlap-4 {
    width: 30%;
  }

  .dashboard .mainCont {
    width: 70%;
  }

  .dashboard .component .vector-2,
  .dashboard .component .eye,
  .dashboard .component .vector {
    display: none;
  }


  .aboutUs .goalsCont .goals {
    flex-flow: column;
  }

  .aboutUs .text-wrapper-2,
  .founders .text-wrapper-2,
  .rmCenter .text-wrapper-4 {
    font-size: 35px;
  }

  .rmCenter .overlap-3rum,
  .rmCenter .overlap-3 {
    margin-right: 30%;
  }

  .founders .foun,
  .founders .founButh {
    flex-flow: column;
  }

  .founders .founImg {
    width: 70%;
  }

  .landing-page .overlap-group-4enc {
    width: 500px;
  }

  .landing-page .rectangle-20 {
    margin-left: 100px;
  }

  .landing-page .overlap-8 {
    top: 0;
    margin-bottom: 250px;
  }

  .login .overlap-2 {
    width: 80%;
  }
}


@media (max-width:750px) {
  .landing-page .ellipse-2 {
    height: 600px;
    width: 600px;
    margin-top: 100px;
  }

  .landing-page .qimaHome {
    font-size: 30pt;
    top: -120px;
  }

  .landing-page .qimaHomeTxt {
    font-size: 14pt;
    top: -180px;
  }
}


@media (max-width:600px) {

  .landing-page .ellipse-67 {
    height: 200px;
    width: 200px;
  }

  .landing-page .ellipse-2 {
    height: 500px;
    width: 500px;
    margin-top: 100px;
  }

  .landing-page .rectangle-14 {
    top: -100px;
  }

  .landing-page .qimaHome {
    font-size: 20pt;
    top: -70px;
  }

  .landing-page .qimaHomeTxt {
    font-size: 10pt;
    top: -140px;
  }

  .landing-page .relativecont {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }

  .landing-page .overlap-3 {
    height: 100%;
    margin-bottom: -10px;
    position: static;
    direction: rtl;
    right: 0;
    top: 208px;
    z-index: +1;
    margin-top: 50px;
    width: 100%;
  }

  .landing-page .text-wrapper-2 {
    color: #004e8e;
    direction: rtl;
    font-size: 40px;
    font-weight: 400;
    left: 53px;
    letter-spacing: 0;
    line-height: normal;
    position: static;
    right: 0;
    text-align: left;
    top: 0;
    margin-left: 80%;
    width: auto;
  }

  .landing-page .text-wrapper-3 {
    font-size: 15px;
  }

  .landing-page .overlap-7 {
    min-height: 900px;
  }

  .landing-page .p {
    color: #004e8e;
    direction: rtl;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: static;
    width: 80%;
    padding: 10px;
  }

  .landing-page .overlap-group-4 {
    width: 100%;
  }

  .landing-page .ellipse-3 {
    height: 200px;
    width: 200px;
    left: -50px;
  }

  .landing-page .overlap-group-4enc {
    width: 320px;
  }

  .landing-page .text-wrapper-10,
  .landing-page .text-wrapper-7,
  .landing-page .text-wrapper-4 {
    font-size: 24px;
  }

  .landing-page .overlap-8 {
    top: 0;
  }

  .footer .element {
    right: 60%;
    height: 200px;
    top: 70px;
    width: 200px;
    left: auto;
  }

  .footer .social {
    right: 30px;
    top: 80px;
    position: absolute;
  }

  .footer .email {
    left: -10px;
    position: relative;
    top: -30px;
    width: 34px;
    display: none;
  }

  .footer .overlap-group,
  .footer .overlap-group {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer .div {
    width: auto;
    left: 10px;
    position: static;
  }

  .footer .line2 {
    width: 100%;
  }

  .footer .overlap {
    width: 100%;
  }

  .footer .iqons {
    width: 180px;
  }

  .rmCenter .text-wrapper-5 {
    font-size: 35px;

  }

  .dashboard .overlap-9 {
    right: 20px;

  }

  .dashboard .text-wrapper-3 {
    font-size: 15px;
    left: 30px;
    width: auto;
  }

  .rmCenter .yearsDL .cls {
    width: 40%;
    margin-right: 0;
  }

  .rmCenter video {
    width: 100%;
  }

  .dashboard .create {
    right: 20px;
  }

  .aboutUs .text-wrapper-2,
  .founders .text-wrapper-2,
  .rmCenter .text-wrapper-4 {
    font-size: 28px;
  }

  .rmCenter .group {
    height: auto;
    width: auto;
  }

  .rmCenter .frame-2 {
    height: auto;
  }

  .rmCenter .group .ingroup {
    flex-flow: column;
    height: auto;
    width: 200px;
  }

  .rmCenter .year-drop-list {
    margin-top: 200px;
  }

  .rmCenter .overlap-3rum,
  .rmCenter .overlap-3 {
    margin-right: 50%;
  }

  .landing-page .rectangle-20 {
    margin-left: 0;
  }

  .rmCenter .rectangle-3 {
    height: 120px;
  }

  .rmCenter .yearsDL .list {
    align-items: center;
  }

  .aboutUs .aboutUsImg {
    width: 80%;
  }
}

@media (max-width: 400px) {

  .aboutUs .text-wrapper-2,
  .founders .text-wrapper-2,
  .rmCenter .text-wrapper-4 {
    font-size: 20px;
  }

  .rmCenter .overlap-3rum,
  .rmCenter .overlap-3 {
    margin-right: 100%;
  }
}